<template>
  <a-layout class="user-layout-con my-layout">
    <a-layout-header class="user-layout-header flex flex-j-c-sb" :class="{'user-layout-padding-lg': isBreakpoint}" v-show="!($store.state.isHeaderHidden)">
      <div class="header-left-con">
        <div v-show="!($store.state.isLogoHidden)">
          <img :src="$logo || '/images/logo.png'" class="user-logo" v-if="!isBreakpoint" height="28" alt="Logo" @click="onLogoClick">
          <img :src="$logo || '/images/simple-logo.png'" class="user-logo" v-else height="28" alt="Logo" @click="onLogoClick">
          <a-divider type="vertical" style="height: 24px; background-color: rgba(0, 0, 0, 0.2);margin: 0 16px;" />
        </div>
        <span class="page-title">{{ $t('header.userCenter') }}</span>
      </div>
      <div class="header-right-con">
        <header-action :isBreakpoint="isBreakpoint" />
      </div>
    </a-layout-header>
    <a-layout style="display: none;">
      <a-layout-sider
        :width="0"
        class="user-layout-sider"
        breakpoint="lg"
        collapsed-width="0"
        @breakpoint="onBreakpoint">
      </a-layout-sider>
    </a-layout>
    <a-layout class="user-layout-conten-wrap">
      <a-layout-content class="user-layout-content" :class="{'user-layout-padding-lg': isBreakpoint}">
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
// import { UserOutlined } from '@ant-design/icons-vue'
import { defineComponent, onMounted, onUnmounted, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import HeaderAction from '@/components/business/HeaderAction.vue'
export default defineComponent({
  components: {
    HeaderAction
  },

  setup () {
    const router = useRouter()
    const store = useStore()
    let state = reactive({
      isBreakpoint: false,
      selectedLang: null
    })

    const onLogoClick = () => {
      router.push('/')
    }

    const onBreakpoint = (val) => {
      state.isBreakpoint = val
      console.log(val)
    }

    onMounted(() => {
      if (store.state.locale) state.selectedLang = [store.state.locale]
    })

    onUnmounted(() => {
      state = null
    })

    return {
      ...toRefs(state),
      onBreakpoint,
      onLogoClick
    }
  }

})
</script>

<style lang="less">
.user-layout-con {
  width: 100%;
  height: 100%;
  background: #EDEDED;
  position: relative;
  .user-layout-sider {
    // display: none;
  }
  .user-layout-header {
    // padding: 0 120px;
    width: 100%;
    height: 64px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    .user-logo {
      cursor: pointer;
    }
    .page-title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
    }
    .header-right-con {
      .header-icon {
        margin-left: 24px;
        cursor: pointer;
      }
      .header-icon-lg {
        margin-left: 8px;
      }
    }
  }
  .user-layout-conten-wrap {
    padding-top: 10px;
    width: 100%;
    height: calc(100% - 64px);
    position: relative;
    .user-layout-content {
      padding: 0 120px;
      background: #EDEDED;
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
  .user-layout-padding-lg {
    padding: 0 10px !important;
  }
}
</style>
